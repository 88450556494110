html, body, #root, .app, .pushable, .pusher, .appBody {
  min-height: 100vh;
}
.appMenu {
  height: 40px !important;
  justify-content: space-between;
}
.appMenu .crestButton {
  background-color: #bd1245;
  color: #fff;
  display: flex;
  align-items: ;
}
.appMenu .navDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #2c5aaf;
}
.appMenu .navDiv .navContainer {
  display: flex;
}
.appMenu .navDiv .navContainer .headerItem {
  color: #fff;
}
.appMenu .navDiv .signOutDiv {

}
.appMenu button {
  border-radius: 0px!important;
  margin-right: 0px!important;
  height: 100%;
}

.appBody {
    padding: 50px 15px 80px;
}

.menuSidebar {
    width: 200px !important;
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1001 !important;
}
.menuSidebar::-webkit-scrollbar {
  display: none;
}

.menuSidebar .divider {
  margin: 0;
}
.menuSidebar .sideBarItem {
  display: flex!important;
  flex-direction: row!important;
  align-items: center;
}
#solsysMenu > div.item.sideBarItem > h5 {
  margin-top: 0px;
}
#solsysMenu > .sideBarHeader {
  font-weight: bold;
  color: rgb(33, 133, 207);
}
#solsysMenu > a {
  padding: 9px;
  font-size: 13px;
}
#solsysMenu > div.disabled.item.sideBarItem > i, #solsysMenu > a.item.sideBarItem > i {
  margin: 0 15px 0 0 !important;
}
#solsysMenu .sideBarItem i {
  font-size: 13px!important;
}
#solsysMenu .sideBarItem h5 {
  font-size: 15px!important;
  margin-top: 0px;
  font-weight: normal;
}
.solsysButton {
  background-color: #2c5aaf!important;
  color: #fff!important;
}
